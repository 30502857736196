<script>
import appConfig from "@/app.config";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Work Permit Request",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
    },
    data() {
        return {
            title: "Work Permit Request",
            work_location:'',
            equipment:'',
            vendor:'',
            measure_1:'',
            measure_2:'',
            measure_3:'',
            measure_4:'',
            measure_5:'',
            measure_6:'',
            measure_7:'',
            measure_8:'',
            measure_9:'',
            measure_10:'',
            measure_11:'',
            measure_12:'',
            measure_13:'',
            measure_14:'',
            measure_15:'',
            job_watch:'',
            apd_selected:[],
            options_radio: [
                { text: 'Ya', value: 'Ya' },
                { text: 'Tidak', value: 'Tidak' },
                { text: 'NA', value: 'NA' },
            ],
            apd_data:[
                {
                    'value': 'Apd 1',
                    'id': '1'
                },
                {
                    'value': 'Apd 2',
                    'id': '2'
                }
            ]
        };
    },
    computed:{
        parsedInput () {
            return this.apd_selected
        },
        parsedDurationStart(){
            return this.duration_start
        },
        parsedDurationEnd(){
            return this.duration_end
        },
        parsedMeasure1(){
            return this.measure_1
        },
        parsedMeasure2(){
            return this.measure_2
        },
        parsedMeasure3(){
            return this.measure_3
        },
        parsedMeasure4(){
            return this.measure_4
        },
        parsedMeasure5(){
            return this.measure_5
        },
        parsedMeasure6(){
            return this.measure_6
        },
        parsedMeasure7(){
            return this.measure_7
        },
        parsedMeasure8(){
            return this.measure_8
        },
        parsedMeasure9(){
            return this.measure_9
        },
        parsedMeasure10(){
            return this.measure_10
        },
        parsedMeasure11(){
            return this.measure_11
        },
        parsedMeasure12(){
            return this.measure_12
        },
        parsedMeasure13(){
            return this.measure_13
        },
        parsedMeasure14(){
            return this.measure_14
        },
        parsedMeasure15(){
            return this.measure_15
        },
        parsedJobWatch(){
            return this.job_watch
        }
    },
    mounted() {
        
    },
    methods: {
        setWorkLocation(){
            this.$emit('setWorkLocation',this.work_location);
        },
        setEquipment(){
            this.$emit('setEquipment',this.equipment);
        },
        setVendor(){
            this.$emit('setVendor',this.vendor);
        },
        setDurationStart(){
            this.parsedDurationStart
            this.$emit('setDurationStart',this.duration_start);
        },
        setDurationEnd(){
            this.parsedDurationEnd
            this.$emit('setDurationEnd',this.duration_end);
        },
        setMeasure1(){
            this.parsedMeasure1
            this.$emit('setMeasure1',this.measure_1);
        },
        setMeasure2(){
            this.parsedMeasure2
            this.$emit('setMeasure2',this.measure_2);
        },setMeasure3(){
            this.parsedMeasure3
            this.$emit('setMeasure3',this.measure_3);
        },setMeasure4(){
            this.parsedMeasure4
            this.$emit('setMeasure4',this.measure_4);
        },setMeasure5(){
            this.parsedMeasure5
            this.$emit('setMeasure5',this.measure_5);
        },setMeasure6(){
            this.parsedMeasure6
            this.$emit('setMeasure6',this.measure_6);
        },setMeasure7(){
            this.parsedMeasure7
            this.$emit('setMeasure7',this.measure_7);
        },setMeasure8(){
            this.parsedMeasure8
            this.$emit('setMeasure8',this.measure_8);
        },setMeasure9(){
            this.parsedMeasure9
            this.$emit('setMeasure9',this.measure_9);
        },setMeasure10(){
            this.parsedMeasure10
            this.$emit('setMeasure10',this.measure_10);
        },setMeasure11(){
            this.parsedMeasure11
            this.$emit('setMeasure11',this.measure_11);
        },setMeasure12(){
            this.parsedMeasure12
            this.$emit('setMeasure12',this.measure_12);
        },setMeasure13(){
            this.parsedMeasure13
            this.$emit('setMeasure13',this.measure_13);
        },setMeasure14(){
            this.parsedMeasure14
            this.$emit('setMeasure14',this.measure_14);
        },setMeasure15(){
            this.parsedMeasure15
            this.$emit('setMeasure15',this.measure_15);
        },setApd(){
            this.parsedInput
            this.$emit('setApd',this.apd_selected);
        },setJobWatch(){
            this.parsedJobWatch
            this.$emit('setJobWatch',this.job_watch);
        }
    },
    watch:{
        parsedInput() {
            this.setApd()
        },
        parsedDurationStart(){
            this.setDurationStart()
        },
        parsedDurationEnd(){
            this.setDurationEnd()
        },
        parsedMeasure1(){
            this.setMeasure1()
        },
        parsedMeasure2(){
            this.setMeasure2()
        },
        parsedMeasure3(){
            this.setMeasure3()
        },
        parsedMeasure4(){
            this.setMeasure4()
        },
        parsedMeasure5(){
            this.setMeasure5()
        },
        parsedMeasure6(){
            this.setMeasure6()
        },
        parsedMeasure7(){
            this.setMeasure7()
        },
        parsedMeasure8(){
            this.setMeasure8()
        },
        parsedMeasure9(){
            this.setMeasure9()
        },
        parsedMeasure10(){
            this.setMeasure10()
        },
        parsedMeasure11(){
            this.setMeasure11()
        },
        parsedMeasure12(){
            this.setMeasure12()
        },
        parsedMeasure13(){
            this.setMeasure13()
        },
        parsedMeasure14(){
            this.setMeasure14()
        },
        parsedMeasure15(){
            this.setMeasure15()
        },
        parsedJobWatch(){
            this.setJobWatch()
        }
    }
};
</script>
<template>
    <b-card border-variant="dark" header="Detail Request">
        <b-card-body>
            <b-form-group label="Detail Lokasi Pekerjaan" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Detail Lokasi Pekerjaan" type="text" v-model="work_location" @keyup="setWorkLocation"></b-form-input>
            </b-form-group>
            <b-form-group label="Tools / Equipment khusus untuk digunakan" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Tools / Equipment khusus untuk digunakan" type="text" v-model="equipment" @keyup="setEquipment"></b-form-input>
            </b-form-group>
            <b-form-group label="Vendor representative" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Vendor representative" type="text" v-model="vendor" @keyup="setVendor"></b-form-input>
            </b-form-group>
            <div class="row">
                <div class="col-md-6">
                    <b-form-group label="Work Duration (8 Hours)" label-for="formrow-nama-role-input">
                        <b-form-input id="formrow-slug-role-input" placeholder="Work Duration (8 Hours)" type="time" v-model="duration_start" @change="setDurationStart"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group label="To" label-for="formrow-nama-role-input">
                        <b-form-input id="formrow-slug-role-input" placeholder="Hingga" type="time" v-model="duration_end" @change="setDurationEnd"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Measure To Be Taken">
        <b-card-body>
            <b-form-group label="1. Apakah anak tangga bebas dari oli, minyak, pelumas, atau segala jenis bahan yang licin?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="measure_1"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_1"
                    @change="setMeasure1"
                />
            </b-form-group>
            <b-form-group label="2. Apakah kaki tangga memakai alas anti-slip?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-2"
                    v-model="measure_2"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_2"
                    @change="setMeasure2"
                />
            </b-form-group>
            <b-form-group label="3. Apakah scaffolding sudah dilengkapi lifeline?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-3"
                    v-model="measure_3"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_3"
                    @change="setMeasure3"
                />
            </b-form-group>
            <b-form-group label="4. Apakah scaffolding sudah dilengkapi hoist arm attachment?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-4"
                    v-model="measure_4"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_4"
                    @change="setMeasure4"
                />
            </b-form-group>
            <b-form-group label="5. Apakah scaffolding sudah dilengkapi akses tangga?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-5"
                    v-model="measure_5"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_5"
                    @change="setMeasure5"
                />
            </b-form-group>
            <b-form-group label="6. Apakah scaffolding sudah dilengkapi toe board?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-6"
                    v-model="measure_6"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_6"
                    @change="setMeasure6"
                />
            </b-form-group>
            <b-form-group label="7. Apakah scaffolding sudah dilengkapi guardrail/handrail?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-7"
                    v-model="measure_7"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_7"
                    @change="setMeasure7"
                />
            </b-form-group>
            <b-form-group label="8. Apakah mudsill tersedia?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-8"
                    v-model="measure_8"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_8"
                    @change="setMeasure8"
                />
            </b-form-group>
            <b-form-group label="9. Apakah alas plat dengan sekrup sudah terpasang dan dikuatkan ke mudsill?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-9"
                    v-model="measure_9"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_9"
                    @change="setMeasure9"
                />
            </b-form-group>
            <b-form-group label="10. Apakah pengunci roda (locking castore) tersedia? (untuk jenis rolling scaffold)" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-10"
                    v-model="measure_10"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_10"
                    @change="setMeasure10"
                />
            </b-form-group>
            <b-form-group label="11. Apakah full body harness tersedia?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-11"
                    v-model="measure_11"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_11"
                    @change="setMeasure11"
                />
            </b-form-group>
            <b-form-group label="12. Apakah full body harness yang digunakan dilengkapi dengan titik-titik pengikat serta lanyard yang standar dan masih layak pakai?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-12"
                    v-model="measure_12"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_12"
                    @change="setMeasure12"
                />
            </b-form-group>
            <b-form-group label="13. Lifeline kondisinya kuat, tahan gores, stabil terhadap bahan kimia, bisa diperluas, tidak cacat, dibalut pelapis ketika melalui ujung yang tajam, mengikat dan menyambung, dan dikencangkan pada tiang pancang yang kuat. " v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-13"
                    v-model="measure_13"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_13"
                    @change="setMeasure13"
                />
            </b-form-group>
            <b-form-group label="14. Terdapat penandaan dan barikade yang jelas selama pekerjaan berlangsung." v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-14"
                    v-model="measure_14"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_14"
                    @change="setMeasure14"
                />
            </b-form-group>
            <b-form-group label="15. Personel yang melakukan pekerjaan sudah memiliki kualifikasi K3 yang relevan." v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-15"
                    v-model="measure_15"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_15"
                    @change="setMeasure15"
                />
            </b-form-group>
            <b-form-group label="APD yang diperlukan (Lihat General permit)" label-for="formrow-nama-role-input">
                <v-select :options="apd_data" label="value" v-model="apd_selected" @change="setApd"></v-select>
            </b-form-group>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Job Site Inspection / Implementation of Safety Measures">
        <b-card-body>
            <b-form-group label="Apakah pekerjaan ini harus dihadiri oleh pengawas?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="job_watch"
                    v-model="job_watch"
                    :aria-describedby="ariaDescribedby"
                    name="job_watch"
                    @change="setJobWatch"
                >
                    <b-form-radio value="At Job Start">At Job Start</b-form-radio>
                    <b-form-radio value="Continuously">Continuously</b-form-radio>
                    <b-form-radio value="No">No</b-form-radio>
                </b-form-radio-group>
                </b-form-group>
        </b-card-body>
    </b-card>
</template>
