<script>
import appConfig from "@/app.config";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Work Permit Request",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
    },
    data() {
        return {
            title: "Work Permit Request",
            work_location:'',
            equipment:'',
            vendor:'',
            measure_1:'',
            measure_2:'',
            measure_3:'',
            measure_4:'',
            measure_5:'',
            measure_6:'',
            measure_7:'',
            measure_8:'',
            measure_9:'',
            measure_10:'',
            job_watch:'',
            apd_selected:[],
            options_radio: [
                { text: 'Ya', value: 'Ya' },
                { text: 'Tidak', value: 'Tidak' },
                { text: 'NA', value: 'NA' },
            ],
            apd_data:[
                {
                    'value': 'Apd 1',
                    'id': '1'
                },
                {
                    'value': 'Apd 2',
                    'id': '2'
                }
            ]
        };
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<template>
    <b-card border-variant="dark" header="Detail Request">
        <b-card-body>
            <b-form-group label="Detail Lokasi Pekerjaan" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Detail Lokasi Pekerjaan" type="text" v-model="work_location"></b-form-input>
            </b-form-group>
            <b-form-group label="Tools / Equipment khusus untuk digunakan" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Tools / Equipment khusus untuk digunakan" type="text" v-model="equipment"></b-form-input>
            </b-form-group>
            <b-form-group label="Vendor representative" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Vendor representative" type="text" v-model="vendor"></b-form-input>
            </b-form-group>
            <div class="row">
                <div class="col-md-6">
                    <b-form-group label="Work Duration (8 Hours)" label-for="formrow-nama-role-input">
                        <b-form-input id="formrow-slug-role-input" placeholder="Work Duration (8 Hours)" type="time" v-model="duration_start"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group label="To" label-for="formrow-nama-role-input">
                        <b-form-input id="formrow-slug-role-input" placeholder="Hingga" type="time" v-model="duration_end"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Measure To Be Taken">
        <b-card-body>
            <b-form-group label="1. Apakah ada kabel bawah tanah dan sudah diidentifikasi lokasinya?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="measure_1"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_1"
                />
            </b-form-group>
            <b-form-group label="2. Apakah lokasi kabel bawah tanah sudah diberi tanda?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-2"
                    v-model="measure_2"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_2"
                />
            </b-form-group>
            <b-form-group label="3. Apakah ada pipa/saluran bawah tanah?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-3"
                    v-model="measure_3"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_3"
                />
            </b-form-group>
            <b-form-group label="4. Apakah lokasi pipa/saluran bawah tanah sudah diberi tanda?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-4"
                    v-model="measure_4"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_4"
                />
            </b-form-group>
            <b-form-group label="5. Di sekitar lokasi yang akan digali telah diperiksa, apakah di dalam tanah terdapat kabel atau saluran pipa gas, minyak, air, jika ada, apakah aman untuk melakukan penggalian dan layanan bawah tanah telah terputus dan terbukti aman?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-5"
                    v-model="measure_5"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_5"
                />
            </b-form-group>
            <b-form-group label="6. Tangga untuk akses masuk ke galian tersedia dan telah diinspeksi?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-6"
                    v-model="measure_6"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_6"
                />
            </b-form-group>
            <b-form-group label="7. Apakah sarana komunikasi seluler memungkinkan dan tersedia? " v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-7"
                    v-model="measure_7"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_7"
                />
            </b-form-group>
            <b-form-group label="8. Apakah semua tools dan equipment aman dan sesuai untuk pekerjaan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-8"
                    v-model="measure_8"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_8"
                />
            </b-form-group>
            <b-form-group label="9. Apakah alat berat yang digunakan sudah teruji dan memiliki lisensi/hasil uji yang masih berlaku?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-9"
                    v-model="measure_9"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_9"
                />
            </b-form-group>
            <b-form-group label="10. Apakah pekerja yang melakukan pekerjaan memiliki kualifikasi K3 yang relevan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-10"
                    v-model="measure_10"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_10"
                />
            </b-form-group>
            <b-form-group label="APD yang diperlukan (Lihat General permit)" label-for="formrow-nama-role-input">
                <v-select :options="apd_data" label="value" v-model="apd_selected" ></v-select>
            </b-form-group>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Job Site Inspection / Implementation of Safety Measures">
        <b-card-body>
            <b-form-group label="Apakah pekerjaan ini harus dihadiri oleh pengawas?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="job_watch"
                    v-model="job_watch"
                    :aria-describedby="ariaDescribedby"
                    name="job_watch"
                >
                    <b-form-radio value="At Job Start">At Job Start</b-form-radio>
                    <b-form-radio value="Continuously">Continuously</b-form-radio>
                    <b-form-radio value="No">No</b-form-radio>
                </b-form-radio-group>
                </b-form-group>
        </b-card-body>
    </b-card>
</template>
