<script>
import appConfig from "@/app.config";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Work Permit Request",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
    },
    data() {
        return {
            title: "Work Permit Request",
            work_location:'',
            equipment:'',
            vendor:'',
            atmospheric_1:'',
            atmospheric_2:'',
            atmospheric_3:'',
            atmospheric_4:'',
            initial_pembacaan_oxygen:'',
            initial_oxygen:'',
            initial_pembacaan_combustible:'',
            initial_combustible:'',
            initial_pembacaan_concentration:'',
            initial_concentration:'',
            initial_pembacaan_other:'',
            initial_other:'',
            final_pembacaan_oxygen:'',
            final_oxygen:'',
            final_pembacaan_combustible:'',
            final_combustible:'',
            final_pembacaan_concentration:'',
            final_concentration:'',
            final_pembacaan_other:'',
            final_other:'',
            measure_1:'',
            measure_2:'',
            measure_3:'',
            measure_4:'',
            measure_5:'',
            measure_6:'',
            measure_7:'',
            measure_8:'',
            job_watch:'',
            apd_selected:[],
            options_radio: [
                { text: 'Ya', value: 'Ya' },
                { text: 'Tidak', value: 'Tidak' },
                { text: 'NA', value: 'NA' },
            ],
            options_hasil: [
                { text: 'Lolos', value: 'Lolos' },
                { text: 'Gagal', value: 'Gagal' },
            ],
            apd_data:[
                {
                    'value': 'Apd 1',
                    'id': '1'
                },
                {
                    'value': 'Apd 2',
                    'id': '2'
                }
            ]
        };
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<template>
    <b-card border-variant="dark" header="Detail Request">
        <b-card-body>
            <b-form-group label="Detail Lokasi Pekerjaan" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Detail Lokasi Pekerjaan" type="text" v-model="work_location"></b-form-input>
            </b-form-group>
            <b-form-group label="Tools / Equipment khusus untuk digunakan" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Tools / Equipment khusus untuk digunakan" type="text" v-model="equipment"></b-form-input>
            </b-form-group>
            <b-form-group label="Vendor representative" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Vendor representative" type="text" v-model="vendor"></b-form-input>
            </b-form-group>
            <div class="row">
                <div class="col-md-6">
                    <b-form-group label="Work Duration (8 Hours)" label-for="formrow-nama-role-input">
                        <b-form-input id="formrow-slug-role-input" placeholder="Work Duration (8 Hours)" type="time" v-model="duration_start"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group label="To" label-for="formrow-nama-role-input">
                        <b-form-input id="formrow-slug-role-input" placeholder="Hingga" type="time" v-model="duration_end"></b-form-input>
                    </b-form-group>
                </div>
            </div>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Atmospheric Gas Testing">
        <b-card-body>
            <b-form-group label="1. Apakah gas test awal diperlukan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="atmospheric_1"
                    v-model="atmospheric_1"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-atmospheric_1"
                />
            </b-form-group>
            <b-form-group label="2. Apakah pengetesan gas diperlukan saat pekerjaan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="atmospheric_2"
                    v-model="atmospheric_2"
                    :aria-describedby="ariaDescribedby"
                    name="radio-atmospheric_2"
                >
                    <b-form-radio value="Periodik">Periodik</b-form-radio>
                    <b-form-radio value="Terus-menerus">Terus-menerus</b-form-radio>
                    <b-form-radio value="Tidak">Tidak</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
            <b-form-group label="3. Apakah instrument gas terkalibrasi dalam sebulan ini?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="atmospheric_3"
                    v-model="atmospheric_3"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-atmospheric_3"
                />
            </b-form-group>
            <b-form-group label="4. Apakah gas testing dilakukan oleh orang kompeten?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="atmospheric_4"
                    v-model="atmospheric_4"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-atmospheric_4"
                />
            </b-form-group>
            <div class="form-group">
                <b for="">Initial Test</b>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Test</th>
                            <th>Pembacaan</th>
                            <th>Hasil</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Oxygen 19.5 - 23.5%</td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="initial_pembacaan_oxygen"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-oxygen"
                                    v-model="initial_oxygen"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-initial_oxygen"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Combustible gas - LEL &lt; 10% </td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="initial_pembacaan_combustible"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-combustible"
                                    v-model="initial_combustible"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-initial_combustible"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Concentration toxic material &lt; 50% TLV </td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="initial_pembacaan_concentration"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-concentration"
                                    v-model="initial_concentration"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-initial_concentration"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Other. Specify </td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="initial_pembacaan_other"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-other"
                                    v-model="initial_other"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-initial_other"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="form-group">
                <b for="">Final Test</b>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Test</th>
                            <th>Pembacaan</th>
                            <th>Hasil</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Oxygen 19.5 - 23.5%</td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="final_pembacaan_oxygen"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-final_oxygen"
                                    v-model="final_oxygen"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-final_oxygen"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Combustible gas - LEL &lt; 10% </td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="final_pembacaan_combustible"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-final_combustible"
                                    v-model="final_combustible"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-final_combustible"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Concentration toxic material &lt; 50% TLV </td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="final_pembacaan_concentration"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-final_concentration"
                                    v-model="final_concentration"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-final_concentration"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Other. Specify </td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="final_pembacaan_other"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-final_other"
                                    v-model="final_other"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-final_other"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Persiapan">
        <b-card-body>
            <b-form-group label="1. Semua koneksi mekanis telah dimatikan (mesin, kran uap, kran air, kran oli, dsb)" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="measure_1"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_1"
                />
            </b-form-group>
            <b-form-group label="2. Aliran listrik telah dimatikan" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-2"
                    v-model="measure_2"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_2"
                />
            </b-form-group>
            <b-form-group label="3. Area kerja telah diberi pembatas" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-3"
                    v-model="measure_3"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_3"
                />
            </b-form-group>
            <b-form-group label="4. Aliran udara yang cukup menggunakan blower, tersedia ketika pekerjaan berlangsung" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-4"
                    v-model="measure_4"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_4"
                />
            </b-form-group>
            <b-form-group label="5. Saluran buangan gas atau asap tersedia" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-5"
                    v-model="measure_5"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_5"
                />
            </b-form-group>
            <b-form-group label="6. Apakah SCBA tersedia dan berfungsi dengan baik?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-6"
                    v-model="measure_6"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_6"
                />
            </b-form-group>
            <b-form-group label="7. Apakah safety line/tripod/harness dan peralatan pendukung lainnya tersedia? " v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-7"
                    v-model="measure_7"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_7"
                />
            </b-form-group>
            <b-form-group label="8. Apakah pekerja memiliki kualifikasi K3 yang relevan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-8"
                    v-model="measure_8"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_8"
                />
            </b-form-group>
            <b-form-group label="APD yang diperlukan (Lihat General permit)" label-for="formrow-nama-role-input">
                <v-select :options="apd_data" label="value" v-model="apd_selected" ></v-select>
            </b-form-group>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Job Site Inspection / Implementation of Safety Measures">
        <b-card-body>
            <b-form-group label="Apakah pekerjaan ini harus dihadiri oleh pengawas?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="job_watch"
                    v-model="job_watch"
                    :aria-describedby="ariaDescribedby"
                    name="job_watch"
                >
                    <b-form-radio value="At Job Start">At Job Start</b-form-radio>
                    <b-form-radio value="Continuously">Continuously</b-form-radio>
                    <b-form-radio value="No">No</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </b-card-body>
    </b-card>
</template>
