<script>
import appConfig from "@/app.config";

import axios from "axios";
import $ from "jquery";
import moment from "moment";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Work Permit Request",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {},
    props: ["data_detail", "work_permit_type", "aksi"],
    data() {
        return {
            title: "Work Permit Request",
            id_permit: null,
            work_location: null,
            instalasi: null,
            equipment: null,
            work_order_no: null,

            process_owner: null,
            performing_task: null,
            jumlah_pekerja: null,

            berlaku_sejak: null,
            hingga_sejak: null,

            dimulai_pukul: null,
            hingga_pukul: null,

            keterangan: null,
            vendor: null,
            uraian_pekerjaan: null,

            attendance_modal: false,
            attendance: {},
            overtime_modal: false,
            overtime: {},

            dokumen_terlampir: [
                {
                    label: "Prosedur",
                    slug: "normal",
                    checklist: false,
                    nama_lain: null,
                    file: [
                        {
                            nama_file: null,
                            url: null,
                        },
                    ],
                },
                {
                    label: "Job Safety & Environment Analysis",
                    slug: "normal",
                    checklist: false,
                    nama_lain: null,
                    file: [
                        {
                            nama_file: null,
                            url: null,
                        },
                    ],
                },
                {
                    label: "Lainnya",
                    slug: "lainnya",
                    checklist: false,
                    nama_lain: null,
                    file: [
                        {
                            nama_file: null,
                            url: null,
                        },
                    ],
                },
                {
                    label: "Drawing, Sketch, Layout",
                    slug: "normal",
                    checklist: false,
                    nama_lain: null,
                    file: [
                        {
                            nama_file: null,
                            url: null,
                        },
                    ],
                },
                {
                    label: "Risk Assessment Study",
                    slug: "normal",
                    checklist: false,
                    nama_lain: null,
                    file: [
                        {
                            nama_file: null,
                            url: null,
                        },
                    ],
                },
                {
                    label: "Lisensi operator boiler",
                    slug: "normal",
                    checklist: false,
                    nama_lain: null,
                    file: [
                        {
                            nama_file: null,
                            url: null,
                        },
                    ],
                },
            ],

            kemungkinan_bahaya: null,
            is_bahaya_rendah: false,
            is_bahaya_tinggi: false,
            bahaya_rendah: null,
            bahaya_tinggi: [],
            tindakan_pencegahan: null,
            apd_data: ["Full Body Harnes", "Respirator", "Protection clothing", "Gloves", "Ear protection", "Lainnya"],
            apd_yang_diperlukan: [],
            // form hot work
            hot_work: [],
            // Energized Electrical Work Permit
            electrical: [],
            confined_space: [],
            heavy_lifting: [],
            work_permit_request_detail: null,
        };
    },
    created() {
        let self = this;
        if (self.data_detail) {
            this.getDetail();
        }
    },
    methods: {
        getDetail() {
            let self = this;
            var data_edit = self.data_detail;
            self.id_permit = data_edit.id;
            var detail_request = JSON.parse(data_edit.detail_request);
            self.work_location = detail_request.work_location;
            self.instalasi = detail_request.instalasi;
            self.equipment = detail_request.equipment;
            self.work_order_no = detail_request.work_order_no;
            self.process_owner = detail_request.process_owner;
            self.performing_task = detail_request.performing_task;
            self.jumlah_pekerja = detail_request.jumlah_pekerja;
            self.berlaku_sejak = detail_request.berlaku_sejak;
            self.hingga_sejak = detail_request.hingga_sejak;
            self.dimulai_pukul = detail_request.dimulai_pukul;
            self.hingga_pukul = detail_request.hingga_pukul;
            self.keterangan = detail_request.keterangan;
            self.vendor = detail_request.vendor;
            self.uraian_pekerjaan = detail_request.uraian_pekerjaan;
            self.dokumen_terlampir = detail_request.dokumen_terlampir;
            var posible_hazard = detail_request.posible_hazard;
            self.is_bahaya_rendah = posible_hazard.is_bahaya_rendah;
            self.is_bahaya_tinggi = posible_hazard.is_bahaya_tinggi;
            console.log(posible_hazard);
            self.kemungkinan_bahaya = posible_hazard.kemungkinan_bahaya;
            self.bahaya_rendah = posible_hazard.bahaya_rendah;
            self.bahaya_tinggi = posible_hazard.bahaya_tinggi;
            self.tindakan_pencegahan = posible_hazard.tindakan_pencegahan;
            self.apd_yang_diperlukan = posible_hazard.apd_yang_diperlukan;

            self.attendance = detail_request.attendance;
            console.log(detail_request);
            self.overtime = detail_request.overtime;

            // WP Type
            if (data_edit.work_permit_request_detail) {
                var raw_detail = [];
                data_edit.work_permit_request_detail.forEach(function (val) {
                    var json_data = JSON.parse(val?.detail_data);
                    val.json_detail = json_data;
                    raw_detail.push(val);
                });
                self.work_permit_request_detail = raw_detail;
            }
            console.log("detail_request");
        },
        inputFileDocument(keys) {
            let self = this;
            if ($("#files-doc-" + keys)[0].files[0]) {
                if ($("#files-doc-" + keys)[0].files[0].size < 2242880) {
                    $("#duloading-" + keys).html('<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>');
                    var attachment_document_in = this.dokumen_terlampir[keys];
                    var urlres = process.env.VUE_APP_BACKEND_URL;
                    var FormData = require("form-data");
                    var data = new FormData();
                    data.append("file", $("#files-doc-" + keys)[0].files[0]);
                    var config = {
                        method: "post",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit/upload",
                        headers: {
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                        data: data,
                    };
                    axios(config)
                        .then(function (response) {
                            attachment_document_in.file = response.data.data.file;
                            attachment_document_in.url = response.data.data.url;
                            urlres += response.data.data.url;
                            $("#duloading-" + keys).html('<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' + urlres + '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> See Document</span></a>');
                            self.dokumen_terlampir[keys] = attachment_document_in;
                        })
                        .catch((e) => {
                            if (e?.response?.data?.data?.error) {
                                $("#duloading-" + keys).html('<span class="badge bg-danger p-1"><i class="fa fa-times"></i> ' + e?.response?.data?.data?.error + " </span>");
                            }
                            console.log(e.response);
                        });
                } else {
                    alert("Max File 2 MB");
                }
            }
        },
        seeDocument(url) {
            window.open(process.env.VUE_APP_BACKEND_URL + url, "_blank");
        },
        fullDateTimeFormat(date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
};
</script>
<template>
    <b-card border-variant="dark" header="Detail Request">
        <b-card-body>
            <table class="table table-sm table-striped table-borderles">
                <tbody>
                    <tr>
                        <th colspan="3">Building</th>
                        <th colspan="3">Instalasi</th>
                        <th colspan="3">Equipment</th>
                        <th colspan="3">No. Work Order (WO)</th>
                    </tr>
                    <tr>
                        <td colspan="3">{{ work_location }}</td>
                        <td colspan="3">{{ instalasi }}</td>
                        <td colspan="3">{{ equipment }}</td>
                        <td colspan="3">{{ work_order_no }}</td>
                    </tr>
                    <tr>
                        <th colspan="4">Process Owner</th>
                        <th colspan="4">Performing Task / Company</th>
                        <th colspan="4">Jumlah Pekerja (Orang)</th>
                    </tr>
                    <tr>
                        <td colspan="4">{{ process_owner }}</td>
                        <td colspan="4">{{ performing_task }}</td>
                        <td colspan="4">{{ jumlah_pekerja }}</td>
                    </tr>
                    <tr>
                        <th colspan="4">Berlaku Sejak :</th>
                        <th colspan="4">Hingga</th>
                        <th colspan="4">Keterangan</th>
                    </tr>
                    <tr>
                        <td colspan="4">{{ berlaku_sejak }}</td>
                        <td colspan="4">{{ hingga_sejak }}</td>
                        <td colspan="4" rowspan="3">
                            {{ keterangan }}
                        </td>
                    </tr>
                    <tr>
                        <th colspan="4">Dimulai pada pukul</th>
                        <th colspan="4">Hingga</th>
                    </tr>
                    <tr>
                        <td colspan="4">{{ dimulai_pukul }}</td>
                        <td colspan="4">{{ hingga_pukul }}</td>
                    </tr>
                    <tr>
                        <th colspan="12">Vendor Representative</th>
                    </tr>
                    <tr>
                        <td colspan="12">{{ vendor }}</td>
                    </tr>
                    <tr>
                        <th colspan="12">Uraian Pekerjaan</th>
                    </tr>
                    <tr>
                        <td colspan="12">{{ uraian_pekerjaan }}</td>
                    </tr>
                    <tr>
                        <th colspan="12">Dokumen Terlampir</th>
                    </tr>
                    <tr>
                        <td colspan="12">
                            <div class="row">
                                <div v-for="(row_data, key_data) in dokumen_terlampir" :key="key_data" class="col-md-4">
                                    <div class="form-check" v-if="row_data.slug != 'overtime'">
                                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                        <label for="gridCheck">
                                            {{ row_data.label }}
                                            <span v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                {{ row_data.nama_lain }}
                                            </span>
                                        </label>
                                        <div v-if="row_data.checklist && row_data.slug == 'attendance'">
                                            <div class="btn btn-sm btn-warning bg-warning" @click="attendance_modal = true"><i class="bx bx-eye"></i> Attendance List</div>
                                        </div>

                                        <div v-if="row_data.checklist && row_data.slug != 'attendance'">
                                            <table class="table table-sm table-bordered mb-0">
                                                <thead class="bg-dark text-white text-center">
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Nama File</th>
                                                        <th>File</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                        <td>{{ key_dt + 1 }}</td>
                                                        <td>
                                                            {{ row_dt.nama_file }}
                                                        </td>
                                                        <td>
                                                            <div v-if="row_dt.url">
                                                                <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Posible Hazard">
        <b-card-body>
            <table class="table table-sm table-striped table-borderles">
                <tbody>
                    <tr>
                        <th>Overtime Permit</th>
                    </tr>
                    <tr>
                        <td>
                            <div v-for="(row_data, key_data) in dokumen_terlampir" :key="key_data" class="col-md-4">
                                <div class="form-check" v-if="row_data.slug == 'overtime'">
                                    <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                    <label for="gridCheck">
                                        {{ row_data.label }}
                                    </label>
                                    <div v-if="row_data.checklist">
                                        <div class="btn btn-sm btn-warning bg-warning" @click="overtime_modal = true"><i class="bx bx-eye"></i> Overtime Permit</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>Kemungkinan Bahaya:</th>
                    </tr>
                    <tr>
                        <td>Bahaya Rendah, Jelaskan</td>
                    </tr>
                    <tr>
                        <td>{{ bahaya_rendah }}</td>
                    </tr>
                    <tr>
                        <td>Bahaya Tinggi, Pilih dibawah ini</td>
                    </tr>
                    <tr>
                        <td>
                            <div class="row">
                                <div v-for="(row_bahaya_tinggi, key_bahaya_tinggi) in bahaya_tinggi" :key="key_bahaya_tinggi" class="col-md-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_bahaya_tinggi.checklist" disabled />
                                        <label for="gridCheck">
                                            {{ row_bahaya_tinggi.label }}
                                            <span v-if="row_bahaya_tinggi.checklist && row_bahaya_tinggi.slug == 'lainnya'">
                                                {{ row_bahaya_tinggi.nama_bahaya }}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Bahaya Rendah, Jelaskan</td>
                    </tr>
                    <tr>
                        <td>{{ bahaya_rendah }}</td>
                    </tr>
                    <tr>
                        <th>Tindakan Pencegahan Yang Diperlukan</th>
                    </tr>
                    <tr>
                        <td>{{ tindakan_pencegahan }}</td>
                    </tr>
                    <tr>
                        <th>APD Yang Diperlukan</th>
                    </tr>
                    <tr>
                        <td>
                            <div class="row">
                                <div v-for="(row_data, key_data) in apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                        <label for="gridCheck">
                                            {{ row_data.label }}
                                        </label>
                                        <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                            {{ row_data.nama_lain }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-card-body>
    </b-card>
    <div v-if="work_permit_request_detail.length >= 1">
        <b-card border-variant="dark" header="Work Type Detail">
            <b-card-body>
                <div class="row">
                    <div class="col-md-12">
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item" v-for="(val, key) in work_permit_request_detail" :key="key">
                                <h2 class="accordion-header" :id="'heading' + key">
                                    <button
                                        class="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        :data-bs-target="'#' + val.slug + key"
                                        :aria-expanded="{
                                            true: key == 0,
                                            false: key != 0,
                                        }"
                                        :aria-controls="val.slug + key">
                                        {{ val.work_permit_type_nama }} - {{ fullDateTimeFormat(val.json_detail.tanggal) }}
                                        <!-- <span v-if="val.permit_process == 'closing'"> - Update Curent </span> -->
                                    </button>
                                </h2>
                                <div :id="val.slug + key" class="accordion-collapse collapse" :class="{ show: key == 0 }" :aria-labelledby="'heading' + key" data-bs-parent="#accordionExample">
                                    <!-- if hot work -->
                                    <div class="px-3" v-if="val.slug == 'hot_work'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-sm table-striped table-borderles">
                                                    <tbody>
                                                        <tr>
                                                            <th>Detail Lokasi Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_lokasi }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Detail Hot Work yang Dilakukan (Cutting/Grinding/Welding/Lainnya):<br /></th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_hot_work }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vendor Representative</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.vendor_representative }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tanggal</th>
                                                            <td colspan="3">
                                                                {{fullDateTimeFormat(val.json_detail.tanggal)}}
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <th>Durasi Kerja</th>
                                                            <td>{{ val.json_detail.work_duration }}</td>
                                                            <th>Hingga</th>
                                                            <td>{{ val.json_detail.work_duration_sampai }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Peralatan Yang Digunakan</th>
                                                            <td colspan="3">{{ val.json_detail.spesifik_equipment }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>Measures To Be Taken</h4></div>
                                                                <b>Persiapan</b>
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No</th>
                                                                            <th>Item untuk dicek</th>
                                                                            <th style="width: 250px">Ya / Tidak / NA</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="(data_cp, key_cp) in val.json_detail.checklist_persiapan" :key="key_cp">
                                                                            <td>
                                                                                {{ key_cp + 1 }}
                                                                            </td>
                                                                            <td>
                                                                                <div v-html="data_cp.label"></div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th colspan="4">Lampiran</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val.json_detail.lampiran" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" disabled />
                                                                            <label>
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                            <div v-if="row_data.checklist">
                                                                                <table class="table table-sm table-bordered mb-0">
                                                                                    <thead class="bg-dark text-white text-center">
                                                                                        <tr>
                                                                                            <th>No</th>
                                                                                            <th>Nama File</th>
                                                                                            <th>File</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                                            <td>{{ key_dt + 1 }}</td>
                                                                                            <td>
                                                                                                {{ row_dt.nama_file }}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div v-if="row_dt.url">
                                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">APD Yang Diperlukan</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val?.json_detail?.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                                                            <label for="gridCheck">
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">ATMOSPHERIC GAS TESTING (DIISI OLEH HSE)</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah gas awal diperlukan</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="val.json_detail.apakah_gas_awal_diperlukan" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="val.json_detail.apakah_gas_awal_diperlukan" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="val.json_detail.apakah_gas_awal_diperlukan" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Apakah pengetesan gas diperlukan saat pekerjaan?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Periodik" v-model="val.json_detail.apakah_pengetesan_gas_diperlukan" disabled />
                                                                                    <label for="gridCheck"> Periodik </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Terus-menerus" v-model="val.json_detail.apakah_pengetesan_gas_diperlukan" disabled />
                                                                                    <label for="gridCheck"> Terus-menerus </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="val.json_detail.apakah_pengetesan_gas_diperlukan" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Apakah instrument gas terkalibrasi dalam sebulan ini?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="val.json_detail.apakah_instrumen_gas_terkalibrasi" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="val.json_detail.apakah_instrumen_gas_terkalibrasi" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="val.json_detail.apakah_instrumen_gas_terkalibrasi" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Apakah gas testing dilakukan oleh orang kompeten?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="val.json_detail.apakah_gas_testing_dilakukan_oleh_orang_kompeten" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="val.json_detail.apakah_gas_testing_dilakukan_oleh_orang_kompeten" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="val.json_detail.apakah_gas_testing_dilakukan_oleh_orang_kompeten" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Test</th>
                                                                            <th>Pembacaan (Atas, Tengah, Bawah)</th>
                                                                            <th>Hasil</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(val_initial, key_initial) in val?.json_detail?.initial_test" :key="key_initial">
                                                                            <td>{{ val_initial.label }}</td>
                                                                            <td>
                                                                                {{ val_initial.pembacaan }}
                                                                            </td>
                                                                            <td>
                                                                                {{ val_initial.hasil }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>ACCEPTANCE</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <b>ATMOSPHERIC GAS TESTING (DIISI OLEH HSE)</b>
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas / Fire Watch?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="val.json_detail.apakah_pekerjaan_dihadiri_fire_watch" disabled />
                                                                                    <label for="gridCheck"> At Job Start </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="val.json_detail.apakah_pekerjaan_dihadiri_fire_watch" disabled />
                                                                                    <label for="gridCheck"> Continuously </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="No" v-model="val.json_detail.apakah_pekerjaan_dihadiri_fire_watch" disabled />
                                                                                    <label for="gridCheck"> No </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Nama Fire Watch</th>
                                                                            <td colspan="3">
                                                                                {{ val.json_detail.nama_fire_watch }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- if excavation -->
                                    <div class="px-3" v-if="val.slug == 'excavation'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-sm table-striped table-borderles">
                                                    <tbody>
                                                        <tr>
                                                            <th>Detail Lokasi Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_lokasi }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tools / Equipment khusus untuk digunakan:<br /></th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.tools }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vendor Representative</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.vendor_representative }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tanggal</th>
                                                            <td colspan="3">
                                                                {{fullDateTimeFormat(val.json_detail.tanggal)}}
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <th>Durasi Kerja</th>
                                                            <td>{{ val.json_detail.work_duration }}</td>
                                                            <th>Hingga</th>
                                                            <td>{{ val.json_detail.work_duration_sampai }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Peralatan Yang Digunakan</th>
                                                            <td colspan="3">{{ val.json_detail.tools }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>Measures To Be Taken</h4></div>
                                                                <b>Persiapan</b>
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No</th>
                                                                            <th>Item untuk dicek</th>
                                                                            <th style="width: 250px">Ya / Tidak / NA</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="(data_cp, key_cp) in val.json_detail.checklist_persiapan" :key="key_cp">
                                                                            <td>
                                                                                {{ key_cp + 1 }}
                                                                            </td>
                                                                            <td>
                                                                                <div v-html="data_cp.label"></div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th colspan="4">Lampiran</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val.json_detail.lampiran" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" disabled />
                                                                            <label>
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                            <div v-if="row_data.checklist">
                                                                                <table class="table table-sm table-bordered mb-0">
                                                                                    <thead class="bg-dark text-white text-center">
                                                                                        <tr>
                                                                                            <th>No</th>
                                                                                            <th>Nama File</th>
                                                                                            <th>File</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                                            <td>{{ key_dt + 1 }}</td>
                                                                                            <td>
                                                                                                {{ row_dt.nama_file }}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div v-if="row_dt.url">
                                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">APD Yang Diperlukan</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val?.json_detail?.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                                                            <label for="gridCheck">
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>ACCEPTANCE</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <b>ATMOSPHERIC GAS TESTING (DIISI OLEH HSE)</b>
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas / Fire Watch?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="val.json_detail.apakah_pekerjaan_dihadiri_fire_watch" disabled />
                                                                                    <label for="gridCheck"> At Job Start </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="val.json_detail.apakah_pekerjaan_dihadiri_fire_watch" disabled />
                                                                                    <label for="gridCheck"> Continuously </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="No" v-model="val.json_detail.apakah_pekerjaan_dihadiri_fire_watch" disabled />
                                                                                    <label for="gridCheck"> No </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <!-- <tr>
                                                                            <th>Nama Fire Watch</th>
                                                                            <td colspan="3">
                                                                                {{ val.json_detail.nama_fire_watch }}
                                                                            </td>
                                                                        </tr> -->
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- if work at height -->
                                    <div class="px-3" v-if="val.slug == 'work_at_height'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-sm table-striped table-borderles">
                                                    <tbody>
                                                        <tr>
                                                            <th>Detail Lokasi Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_lokasi }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Detail Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_pekerjaan }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tools / Equipment khusus untuk digunakan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.tools }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vendor Representative</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.vendor_representative }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tanggal</th>
                                                            <td colspan="3">
                                                                {{fullDateTimeFormat(val.json_detail.tanggal)}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Durasi Kerja</th>
                                                            <td>{{ val.json_detail.work_duration }}</td>
                                                            <th>Hingga</th>
                                                            <td>{{ val.json_detail.work_duration_sampai }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>Measures To Be Taken</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <b>Persiapan</b>
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No</th>
                                                                            <th>Item untuk dicek</th>
                                                                            <th style="width: 250px">Ya / Tidak / NA</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="(data_cp, key_cp) in val.json_detail.checklist_persiapan" :key="key_cp">
                                                                            <td>
                                                                                {{ key_cp + 1 }}
                                                                            </td>
                                                                            <td>
                                                                                <div v-html="data_cp.label"></div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">Lampiran</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val.json_detail.lampiran" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" disabled />
                                                                            <label>
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                            <div v-if="row_data.checklist">
                                                                                <table class="table table-sm table-bordered mb-0">
                                                                                    <thead class="bg-dark text-white text-center">
                                                                                        <tr>
                                                                                            <th>No</th>
                                                                                            <th>Nama File</th>
                                                                                            <th>File</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                                            <td>{{ key_dt + 1 }}</td>
                                                                                            <td>
                                                                                                {{ row_dt.nama_file }}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div v-if="row_dt.url">
                                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">APD Yang Diperlukan</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val?.json_detail?.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                                                            <label for="gridCheck">
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>ACCEPTANCE</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> At Job Start </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> Continuously </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="No" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> No </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- confined_space -->
                                    <div class="px-3" v-if="val.slug == 'confined_space'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-sm table-striped table-borderles">
                                                    <tbody>
                                                        <tr>
                                                            <th>Detail Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_pekerjaan }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Detail Lokasi Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_lokasi }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tools / Equipment khusus untuk digunakan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.tools }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vendor Representative</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.vendor_representative }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tanggal</th>
                                                            <td colspan="3">
                                                                {{fullDateTimeFormat(val.json_detail.tanggal)}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Durasi Kerja</th>
                                                            <td>{{ val.json_detail.work_duration }}</td>
                                                            <th>Hingga</th>
                                                            <td>{{ val.json_detail.work_duration_sampai }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>Measures To Be Taken</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <label for="">ATMOSPHERIC GAS TESTING</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah gas test awal diperlukan?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="val.json_detail.apakah_tes_gas_awal_dipelukan" disabled />
                                                                                    <label> Ya </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center"></th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="val.json_detail.apakah_tes_gas_awal_dipelukan" disabled />
                                                                                    <label> Tidak </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Apakah pengetesan gas diperlukan saat pekerjaan?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Periodik" v-model="val.json_detail.apakah_pengetesan_gas_diperlukan_saat_pekerjaan" disabled />
                                                                                    <label> Periodik </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Terus-menerus" v-model="val.json_detail.apakah_pengetesan_gas_diperlukan_saat_pekerjaan" disabled />
                                                                                    <label> Terus-menerus </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="val.json_detail.apakah_pengetesan_gas_diperlukan_saat_pekerjaan" disabled />
                                                                                    <label> Tidak </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Apakah instrument gas terkalibrasi dalam sebulan ini?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="val.json_detail.apakah_instrumen_gas_terkalibrasi" disabled />
                                                                                    <label> Ya </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="val.json_detail.apakah_instrumen_gas_terkalibrasi" disabled />
                                                                                    <label> Tidak </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="val.json_detail.apakah_instrumen_gas_terkalibrasi" disabled />
                                                                                    <label> NA </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Apakah gas testing dilakukan oleh orang kompeten?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="val.json_detail.apakah_gas_testing_dilakukan_oleh_orang_kompeten" disabled />
                                                                                    <label> Ya </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="val.json_detail.apakah_gas_testing_dilakukan_oleh_orang_kompeten" disabled />
                                                                                    <label> Tidak </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="val.json_detail.apakah_gas_testing_dilakukan_oleh_orang_kompeten" disabled />
                                                                                    <label> NA </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <table>
                                                                    <tr>
                                                                        <th>Initial Test</th>
                                                                        <td>
                                                                            <table class="table table-sm table-bordered table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Test</th>
                                                                                        <th>Pembacaan (Atas, Tengah, Bawah)</th>
                                                                                        <th>Hasil</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(val_initial, key_initial) in val.json_detail.initial_test" :key="key_initial">
                                                                                        <td>{{ val_initial.label }}</td>
                                                                                        <td>
                                                                                            {{ val_initial.pembacaan }}
                                                                                        </td>
                                                                                        <td>
                                                                                            {{ val_initial.hasil }}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Final Test</th>
                                                                        <td>
                                                                            <table class="table table-sm table-bordered table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Test</th>
                                                                                        <th>Pembacaan (Atas, Tengah, Bawah)</th>
                                                                                        <th>Hasil</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(val_final, key_final) in val.json_detail.final_test" :key="key_final">
                                                                                        <td>{{ val_final.label }}</td>
                                                                                        <td>
                                                                                            {{ val_final.pembacaan }}
                                                                                        </td>
                                                                                        <td>
                                                                                            {{ val_final.hasil }}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">
                                                                <b>Persiapan</b>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No</th>
                                                                            <th>Item untuk dicek</th>
                                                                            <th style="width: 250px">Ya / Tidak / NA</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="(data_cp, key_cp) in val.json_detail.checklist_persiapan" :key="key_cp">
                                                                            <td>
                                                                                {{ key_cp + 1 }}
                                                                            </td>
                                                                            <td>
                                                                                <div v-html="data_cp.label"></div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">Lampiran</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val.json_detail.lampiran" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" disabled />
                                                                            <label>
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                            <div v-if="row_data.checklist">
                                                                                <table class="table table-sm table-bordered mb-0">
                                                                                    <thead class="bg-dark text-white text-center">
                                                                                        <tr>
                                                                                            <th>No</th>
                                                                                            <th>Nama File</th>
                                                                                            <th>File</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                                            <td>{{ key_dt + 1 }}</td>
                                                                                            <td>
                                                                                                {{ row_dt.nama_file }}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div v-if="row_dt.url">
                                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">APD Yang Diperlukan</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val?.json_detail?.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                                                            <label for="gridCheck">
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>ACCEPTANCE</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">ATMOSPHERIC GAS TESTING (DIISI OLEH HSE)</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> At Job Start </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> Continuously </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="No" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> No </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- energized -->
                                    <div class="px-3" v-if="val.slug == 'energized'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-sm table-striped table-borderles">
                                                    <tbody>
                                                        <tr>
                                                            <th>Detail Lokasi Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_lokasi }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tools / Equipment khusus untuk digunakan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.tools }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vendor Representative</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.vendor_representative }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tanggal</th>
                                                            <td colspan="3">
                                                                {{fullDateTimeFormat(val.json_detail.tanggal)}}
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <th>Durasi Kerja</th>
                                                            <td>{{ val.json_detail.work_duration }}</td>
                                                            <th>Hingga</th>
                                                            <td>{{ val.json_detail.work_duration_sampai }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>Measures To Be Taken</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <label for="">ATMOSPHERIC GAS TESTING</label>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">
                                                                <b>Persiapan</b>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No</th>
                                                                            <th>Item untuk dicek</th>
                                                                            <th style="width: 250px">Ya / Tidak / NA</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="(data_cp, key_cp) in val.json_detail.checklist_persiapan" :key="key_cp">
                                                                            <td>
                                                                                {{ key_cp + 1 }}
                                                                            </td>
                                                                            <td>
                                                                                <div v-html="data_cp.label"></div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">Lampiran</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val.json_detail.lampiran" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" disabled />
                                                                            <label>
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                            <div v-if="row_data.checklist">
                                                                                <table class="table table-sm table-bordered mb-0">
                                                                                    <thead class="bg-dark text-white text-center">
                                                                                        <tr>
                                                                                            <th>No</th>
                                                                                            <th>Nama File</th>
                                                                                            <th>File</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                                            <td>{{ key_dt + 1 }}</td>
                                                                                            <td>
                                                                                                {{ row_dt.nama_file }}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div v-if="row_dt.url">
                                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">APD Yang Diperlukan</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val?.json_detail?.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                                                            <label for="gridCheck">
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>ACCEPTANCE</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">Job Site Inspection / Implementation of Safety Measures</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="electrical.apakah_harus_dihadiri_pengawas" />
                                                                                    <label> At Job Start </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="electrical.apakah_harus_dihadiri_pengawas" />
                                                                                    <label> Continuously </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="No" v-model="electrical.apakah_harus_dihadiri_pengawas" />
                                                                                    <label> No </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- heavy_lifting -->
                                    <div class="px-3" v-if="val.slug == 'heavy_lifting'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-sm table-striped table-borderles">
                                                    <tbody>
                                                        <tr>
                                                            <th>Detail Lokasi Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_lokasi }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Peralatan angkut yang digunakan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.peralatan_angkat }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vendor Representative</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.vendor_representative }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tanggal</th>
                                                            <td colspan="3">
                                                                {{fullDateTimeFormat(val.json_detail.tanggal)}}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Durasi Kerja</th>
                                                            <td>{{ val.json_detail.work_duration }}</td>
                                                            <th>Hingga</th>
                                                            <td>{{ val.json_detail.work_duration_sampai }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Metode Pengangkatan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.metode_pengangkatan }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>Measures To Be Taken</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">
                                                                <b>Persiapan</b>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No</th>
                                                                            <th>Item untuk dicek</th>
                                                                            <th style="width: 250px">Ya / Tidak / NA</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="(data_cp, key_cp) in val.json_detail.checklist_persiapan" :key="key_cp">
                                                                            <td>
                                                                                {{ key_cp + 1 }}
                                                                            </td>
                                                                            <td>
                                                                                <div v-html="data_cp.label"></div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">Lampiran</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val.json_detail.lampiran" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" disabled />
                                                                            <label>
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                            <div v-if="row_data.checklist">
                                                                                <table class="table table-sm table-bordered mb-0">
                                                                                    <thead class="bg-dark text-white text-center">
                                                                                        <tr>
                                                                                            <th>No</th>
                                                                                            <th>Nama File</th>
                                                                                            <th>File</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                                            <td>{{ key_dt + 1 }}</td>
                                                                                            <td>
                                                                                                {{ row_dt.nama_file }}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div v-if="row_dt.url">
                                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">APD Yang Diperlukan</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val?.json_detail?.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                                                            <label for="gridCheck">
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>ACCEPTANCE</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">Job Site Inspection / Implementation of Safety Measures</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> At Job Start </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> Continuously </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="No" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> No </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- electrical -->
                                    <div class="px-3" v-if="val.slug == 'electrical'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-sm table-striped table-borderles">
                                                    <tbody>
                                                        <tr>
                                                            <th>Detail Lokasi Pekerjaan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.detail_lokasi }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tools / Equipment khusus untuk digunakan</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.tools }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vendor Representative</th>
                                                            <td colspan="3">
                                                                {{ val.json_detail.vendor_representative }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tanggal</th>
                                                            <td colspan="3">
                                                                {{fullDateTimeFormat(val.json_detail.tanggal)}}
                                                            </td>
                                                        </tr>   
                                                        <tr>
                                                            <th>Durasi Kerja</th>
                                                            <td>{{ val.json_detail.work_duration }}</td>
                                                            <th>Hingga</th>
                                                            <td>{{ val.json_detail.work_duration_sampai }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>Measures To Be Taken</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">
                                                                <b>Persiapan</b>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No</th>
                                                                            <th>Item untuk dicek</th>
                                                                            <th style="width: 250px">Ya / Tidak / NA</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr v-for="(data_cp, key_cp) in val.json_detail.checklist_persiapan" :key="key_cp">
                                                                            <td>
                                                                                {{ key_cp + 1 }}
                                                                            </td>
                                                                            <td>
                                                                                <div v-html="data_cp.label"></div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Ya" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Ya </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Tidak" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> Tidak </label>
                                                                                </div>
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="NA" v-model="data_cp.checklist" disabled />
                                                                                    <label for="gridCheck"> NA </label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">Lampiran</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val.json_detail.lampiran" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="" v-model="row_data.checklist" disabled />
                                                                            <label>
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                            <div v-if="row_data.checklist">
                                                                                <table class="table table-sm table-bordered mb-0">
                                                                                    <thead class="bg-dark text-white text-center">
                                                                                        <tr>
                                                                                            <th>No</th>
                                                                                            <th>Nama File</th>
                                                                                            <th>File</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr v-for="(row_dt, key_dt) in row_data.file" :key="key_dt">
                                                                                            <td>{{ key_dt + 1 }}</td>
                                                                                            <td>
                                                                                                {{ row_dt.nama_file }}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div v-if="row_dt.url">
                                                                                                    <span class="badge bg-success p-1" @click="seeDocument(row_dt.url)"><i class="fa fa-eye"></i> See Document </span>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">APD Yang Diperlukan</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="row">
                                                                    <div v-for="(row_data, key_data) in val?.json_detail?.apd_yang_diperlukan" :key="key_data" class="col-md-4">
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="checkbox" id="gridCheck" v-model="row_data.checklist" disabled />
                                                                            <label for="gridCheck">
                                                                                {{ row_data.label }}
                                                                            </label>
                                                                            <div v-if="row_data.checklist && row_data.slug == 'lainnya'">
                                                                                {{ row_data.nama_lain }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <div class="text-center"><h4>ACCEPTANCE</h4></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th colspan="4">Job Site Inspection / Implementation of Safety Measures</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                <table class="table table-sm table-bordered table-striped">
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Apakah pekerjaan ini harus dihadiri oleh pengawas?</th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="At Job Start" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> At Job Start </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="Continuously" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> Continuously </label>
                                                                                </div>
                                                                            </th>
                                                                            <th class="text-center">
                                                                                <div class="form-check">
                                                                                    <input class="form-check-input" type="radio" value="No" v-model="val.json_detail.apakah_harus_dihadiri_pengawas" disabled />
                                                                                    <label> No </label>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="px-3" v-if="val.slug == 'overtime'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <table class="table table-sm table-striped table-borderles">
                                                    <tbody>
                                                        <tr>
                                                            <th colspan="2">Detail Lokasi Pekerjaan</th>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">{{ val.json_detail.detail_lokasi }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Durasi Kerja</td>
                                                            <td>Hingga</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ val.json_detail.durasi_kerja_dari }}</td>
                                                            <td>{{ val.json_detail.durasi_kerja_sampai }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">
                                                                <table class="table table-sm table-bordered mb-0">
                                                                    <thead class="bg-dark text-white text-center">
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Company Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr v-for="(row_dt, key_dt) in val.json_detail.performing_task" :key="key_dt">
                                                                            <td>
                                                                                {{ row_dt.nama }}
                                                                            </td>
                                                                            <td>
                                                                                {{ row_dt.perusahaan }}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">Description Of Task</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">{{ val.json_detail.description_task }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card-body>
        </b-card>
    </div>
    <b-modal v-model="attendance_modal" hide-footer centered header-class="border-0" title="Attendance List" size="lg">
        <div class="mb-4">
            <table class="table table-sm table-striped table-borderles">
                <tbody>
                    <tr>
                        <th colspan="2">Detail Lokasi Pekerjaan</th>
                    </tr>
                    <tr>
                        <td colspan="2">{{ attendance.detail_lokasi }}</td>
                    </tr>
                    <tr>
                        <td>Durasi Kerja</td>
                        <td>Hingga</td>
                    </tr>
                    <tr>
                        <td>{{ attendance.durasi_kerja_dari }}</td>
                        <td>{{ attendance.durasi_kerja_sampai }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table class="table table-sm table-bordered mb-0">
                                <thead class="bg-dark text-white text-center">
                                    <tr>
                                        <th>Name</th>
                                        <th>Company Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row_dt, key_dt) in attendance.performing_task" :key="key_dt">
                                        <td>
                                            {{ row_dt.nama }}
                                        </td>
                                        <td>
                                            {{ row_dt.perusahaan }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-end mt-3 button-items">
            <b-button variant="light" v-on:click="attendance_modal = false"> <i class="bx bx-x"></i> Close </b-button>
        </div>
    </b-modal>
    <b-modal v-model="overtime_modal" hide-footer centered header-class="border-0" title="Overtime Permit" size="lg">
        <div class="mb-4">
            <table class="table table-sm table-striped table-borderles">
                <tbody>
                    <tr>
                        <th colspan="2">Detail Lokasi Pekerjaan</th>
                    </tr>
                    <tr>
                        <td colspan="2">{{ overtime.detail_lokasi }}</td>
                    </tr>
                    <tr>
                        <th>Durasi Kerja</th>
                        <th>Hingga</th>
                    </tr>
                    <tr>
                        <td>{{ overtime.durasi_kerja_dari }}</td>
                        <td>{{ overtime.durasi_kerja_sampai }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table class="table table-sm table-bordered mb-0">
                                <thead class="bg-dark text-white text-center">
                                    <tr>
                                        <th>Name</th>
                                        <th>Company Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row_dt, key_dt) in overtime.performing_task" :key="key_dt">
                                        <td>
                                            {{ row_dt.nama }}
                                        </td>
                                        <td>
                                            {{ row_dt.perusahaan }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th colspan="2">Description Of Task</th>
                    </tr>
                    <tr>
                        <td colspan="2">{{ overtime.description_task }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-end mt-3 button-items">
            <b-button variant="light" v-on:click="overtime_modal = false"> <i class="bx bx-x"></i> Close </b-button>
        </div>
    </b-modal>
</template>
