<script>
import appConfig from "@/app.config";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Work Permit Request",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
    },
    data() {
        return {
            title: "Work Permit Request",
            work_location:'',
            equipment:'',
            vendor:'',
            specific_equipment:'',
            atmospheric_1:'',
            atmospheric_2:'',
            atmospheric_3:'',
            atmospheric_4:'',
            initial_pembacaan_oxygen:'',
            initial_oxygen:'',
            initial_pembacaan_gas:'',
            initial_gas:'',
            initial_pembacaan_other:'',
            initial_other:'',
            measure_1:'',
            measure_2:'',
            measure_3:'',
            measure_4:'',
            measure_5:'',
            measure_6:'',
            measure_7:'',
            measure_8:'',
            measure_9:'',
            measure_10:'',
            measure_11:'',
            measure_12:'',
            measure_13:'',
            measure_14:'',
            measure_15:'',
            measure_16:'',
            job_watch:'',
            apd_selected:[],
            options_radio: [
                { text: 'Ya', value: 'Ya' },
                { text: 'Tidak', value: 'Tidak' },
                { text: 'NA', value: 'NA' },
            ],
            options_hasil: [
                { text: 'Lolos', value: 'Lolos' },
                { text: 'Gagal', value: 'Gagal' },
            ],
            apd_data:[
                {
                    'value': 'Apd 1',
                    'id': '1'
                },
                {
                    'value': 'Apd 2',
                    'id': '2'
                }
            ]
        };
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<template>
    <b-card border-variant="dark" header="Detail Request">
        <b-card-body>
            <b-form-group label="Detail Lokasi Pekerjaan" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Detail Lokasi Pekerjaan" type="text" v-model="work_location"></b-form-input>
            </b-form-group>
            <b-form-group label="Tools / Equipment khusus untuk digunakan" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Tools / Equipment khusus untuk digunakan" type="text" v-model="equipment"></b-form-input>
            </b-form-group>
            <b-form-group label="Vendor representative" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Vendor representative" type="text" v-model="vendor"></b-form-input>
            </b-form-group>
            <div class="row">
                <div class="col-md-6">
                    <b-form-group label="Work Duration (8 Hours)" label-for="formrow-nama-role-input">
                        <b-form-input id="formrow-slug-role-input" placeholder="Work Duration (8 Hours)" type="time" v-model="duration_start"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-6">
                    <b-form-group label="To" label-for="formrow-nama-role-input">
                        <b-form-input id="formrow-slug-role-input" placeholder="Hingga" type="time" v-model="duration_end"></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <b-form-group label="Spesifik Equipment atau Vessel / Pekerjaan dilaksanakan:" label-for="formrow-nama-role-input">
                <b-form-input id="formrow-slug-role-input" placeholder="Spesifik Equipment atau Vessel / Pekerjaan dilaksanakan" type="text" v-model="specific_equipment"></b-form-input>
            </b-form-group>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Persiapan">
        <b-card-body>
            <b-form-group label="1. Jalur di-disconnect dan/atau dikosongkan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-1"
                    v-model="measure_1"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_1"
                />
            </b-form-group>
            <b-form-group label="2. Peralatan atau area cukup bersih dari tumpahan, kotoran, atau bahan mudah terbakar lainnya?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-2"
                    v-model="measure_2"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_2"
                />
            </b-form-group>
            <b-form-group label="3. Lubang got, pintu, jendela, sambungan selokan ditutup atau dilindungi?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-3"
                    v-model="measure_3"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_3"
                />
            </b-form-group>
            <b-form-group label="4. Peralatan dan operasi yang berdampingan aman dari sudut pandang dampak yang mungkin terjadi pada pekerjaan? (tidak ada pekerjaan pemutus saluran tambahan yang terletak dalam jarak 25 Meter dari titik penyalaan pekerjaan panas)" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-4"
                    v-model="measure_4"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_4"
                />
            </b-form-group>
            <b-form-group label="5. Jika di dalam gedung / bangunan, apakah sprinkler atau sistem deteksi kebakaran tersedia?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-5"
                    v-model="measure_5"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_5"
                />
            </b-form-group>
            <b-form-group label="6. Apakah area terbebas dari debu?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-6"
                    v-model="measure_6"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_6"
                />
            </b-form-group>
            <b-form-group label="7. Apakah terdapat APAR di area pekerjaan yang sudah diinspeksi? " v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-7"
                    v-model="measure_7"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_7"
                />
            </b-form-group>
            <b-form-group label="8. Apakah Hidran dalam kondisi stand by?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-8"
                    v-model="measure_8"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_8"
                />
            </b-form-group>
            <b-form-group label="9. Apakah alat potong/las terata rapi?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-9"
                    v-model="measure_9"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_9"
                />
            </b-form-group>
            <b-form-group label="10. Fire Watch tersedia? (fire watch harus tetap di lokasi kerja untuk 30 menit setelah pekerjaan berhenti)" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-10"
                    v-model="measure_10"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_10"
                />
            </b-form-group>
            <b-form-group label="11. Apakah ada hot surface/permukaan panas?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-11"
                    v-model="measure_11"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_11"
                />
            </b-form-group>
            <b-form-group label="12. Apakah blanket atau pelindung tahan api disediakan untuk melindungi material atau personel yang bersebelahan dari percikan api atau arc flash?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-12"
                    v-model="measure_12"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_12"
                />
            </b-form-group>
            <b-form-group label="13. Apakah inert gas blanketing diperlukan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-13"
                    v-model="measure_13"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_13"
                />
            </b-form-group>
            <b-form-group label="14. Apakah ventilasi umum memadai?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-14"
                    v-model="measure_14"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_14"
                />
            </b-form-group>
            <b-form-group label="15. Apakah pasokan udara segar diperlukan? (Seperti di Confined Space)" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-15"
                    v-model="measure_15"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_15"
                />
            </b-form-group>
            <b-form-group label="16. Apakah semua area di bawah hot work bersih dari bahan mudah terbakar dan area sudah dibarikade?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-group-16"
                    v-model="measure_16"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="radio-measure_16"
                />
            </b-form-group>
            <b-form-group label="APD yang diperlukan (Lihat General permit)" label-for="formrow-nama-role-input">
                <v-select :options="apd_data" label="value" v-model="apd_selected" ></v-select>
            </b-form-group>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Atmospheric Gas Testing">
        <b-card-body>
            <b-form-group label="1. Apakah gas test awal diperlukan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="atmospheric_1"
                    v-model="atmospheric_1"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="atmospheric_1"
                />
            </b-form-group>
            <b-form-group label="2. Apakah pengetesan gas diperlukan saat pekerjaan?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="atmospheric_2"
                    v-model="atmospheric_2"
                    :aria-describedby="ariaDescribedby"
                    name="atmospheric_2"
                >
                    <b-form-radio value="Periodik">Periodik</b-form-radio>
                    <b-form-radio value="Terus-menerus">Terus-menerus</b-form-radio>
                    <b-form-radio value="Tidak">Tidak</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
            <b-form-group label="3. Apakah instrument gas terkalibrasi dalam sebulan ini?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="atmospheric_3"
                    v-model="atmospheric_3"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="atmospheric_3"
                />
            </b-form-group>
            <b-form-group label="4. Apakah gas testing dilakukan oleh orang kompeten?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="atmospheric_4"
                    v-model="atmospheric_4"
                    :options="options_radio"
                    :aria-describedby="ariaDescribedby"
                    name="atmospheric_4"
                />
            </b-form-group>
            <div class="form-group">
                <b for="">Diisi oleh Teknisi Deteksi Gas</b>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Test</th>
                            <th>Pembacaan</th>
                            <th>Hasil</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Gas mudah terbakar- LEL 0%</td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="initial_pembacaan_gas"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-gas"
                                    v-model="initial_gas"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="initial_gas"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Oxygen 19.5 - 23.5%</td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="initial_pembacaan_oxygen"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-oxygen"
                                    v-model="initial_oxygen"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="initial_oxygen"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Lainnya (sebutkan) </td>
                            <td><b-form-input id="formrow-slug-role-input" placeholder="Pembacaan" type="text" v-model="initial_pembacaan_other"></b-form-input></td>
                            <td>
                                <b-form-radio-group
                                    id="radio-group-other"
                                    v-model="initial_other"
                                    :options="options_hasil"
                                    :aria-describedby="ariaDescribedby"
                                    name="initial_other"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-card-body>
    </b-card>
    <b-card border-variant="dark" header="Job Site Inspection / Implementation of Safety Measures">
        <b-card-body>
            <b-form-group label="Apakah pekerjaan ini harus dihadiri oleh pengawas?" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="job_watch"
                    v-model="job_watch"
                    :aria-describedby="ariaDescribedby"
                    name="job_watch"
                >
                    <b-form-radio value="At Job Start">At Job Start</b-form-radio>
                    <b-form-radio value="Continuously">Continuously</b-form-radio>
                    <b-form-radio value="No">No</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </b-card-body>
    </b-card>
</template>
